<template>
    <div class="header-logout">
        <el-dropdown trigger="click" @command='logout'>
            <span class="el-dropdown-link user">
                {{phone}}
                <i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item command='info'>个人信息</el-dropdown-item> -->
                <el-dropdown-item command='退出'>
                    <i class="iconfont icon-tuichu" style="margin-right:10px"></i>
                    退出
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
export default {
    props:{
        phone:String
    },
    methods:{
        logout(cmditem){
            this.$emit('sonlogout',cmditem)
        }
    }
}
</script>

<style lang="less" scoped>
// 强制不换行
/deep/.el-dropdown-menu__item{
    white-space: nowrap;
}
.user{
    height: 54px;
    color: #FF6056!important;
    font-family: Roboto;
    font-style: normal;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 24px!important;
    margin-left: 41px;
}
.user:hover{
    cursor:pointer;
}
</style>