<template>
  <div class="home">
    <el-container>
      <!-- 头部 -->
      <el-header>
        <img src="../assets/logo.png">
        <logout :phone="phone" @sonlogout="logout"/>
      </el-header>
      <!-- 主体内容 -->
      <el-container>
        <!-- 左边导航栏 -->
          <el-aside>
            <el-menu :default-active="activePath" active-text-color="#FF6056" router :unique-opened="true">
              <el-menu-item :index="item.path" @click="menuItem(item.path)" :class="activePath==item.path?'active':''" v-for="item in menu.menuList1" :key="item.name">
                <span slot="title">{{item.name}}</span>
              </el-menu-item>
              <el-submenu :index="item1.path" v-for="(item1,index1) in menu.menuList2" :key="index1">
                <template slot="title">{{item1.name}}</template>
                <el-menu-item :index="item2.path" v-for="(item2,index2) in item1.children" :key="index2" @click="menuItem(item2.path)" :class="activePath==item2.path?'active':''">{{item2.name}}</el-menu-item>
              </el-submenu>
              <el-menu-item :index="item.path" @click="menuItem(item.path)" :class="activePath==item.path?'active':''" v-for="item in menu.menuList3" :key="item.name">
                <span slot="title">{{item.name}}</span>
              </el-menu-item>
            </el-menu>
            <div class="version">
              <div class="version-item">
                <div class="version-left"> WCC </div>
                <div class="version-right"> V1.0.0 </div>
              </div>
              <div class="version-item">
                <div class="version-left"> 客户端 </div>
                <div class="version-right"> V2.0.0 </div>
              </div>
              <div class="version-item">
                <div class="version-left"> 服务端 </div>
                <div class="version-right"> V1.0.0 </div>
              </div>
            </div>
          </el-aside>
        <!-- 右边主体内容 -->
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import global from '../assets/js/global'
import logout from '@/components/home/logout.vue'
export default {
  components:{logout},
  data(){
    return{
      phone:'',
      
      activePath:'/Statistics',// 选中状态
      //导航栏list
      menu:{
        menuList1:[
          {path:'/Statistics',name:'总览'},
          {path:'/UserManagement',name:'用户管理'},
          {path:'/officialAccountsOrApplet',name:'公众号/小程序'},
        ],
        menuList2:[
          {path:'1',name:'订单管理',
            children:[
              {path:'/onlineOrderManagement',name:'在线点餐订单'},
              {path:'/cardOrderManagement',name:'卡券订单'},
              {path:'/rechargeOrderManagement',name:'直充订单'},
              {path:'/mtOrderManagement',name:'美团cps订单'},
              {path:'/elmOrderManagement',name:'饿了么cps订单'},
            ]
          },
          {path:'2',name:'财务管理',
            children:[
              {path:'/cardOrderVerify',name:'卡券订单对账'},
              {path:'/rechargeOrderVerify',name:'直充订单对账'},
              {path:'/onlineOrderVerifu',name:'在线点餐订单对账'},
              {path:'/MeituanCPS',name:'美团cps订单对账'},
              {path:'/ElemeCPS',name:'饿了么cps订单对账'},
              
            ]
          },
        ],
        menuList3:[
          {path:'/AccountSetup',name:'账号设置'}
        ],
      }
    }
  },
  created(){
    // 三级代理隐藏渠道管理
    this.is_three_proxy()
    // 控制背景颜色
    this.is_Statistics()
    // 获取手机号码
    this.getPhone()
  },
  methods:{
    // 获取手机号码
    getPhone(){
      let loginData =JSON.parse(this.$global.getCookie('loginData'))
      this.phone=loginData.phone.substr(0,3) + "****" + loginData.phone.substr(7)
    },
    // 三级代理隐藏渠道管理
    is_three_proxy(){
      if(JSON.parse(this.$global.getCookie('loginData')).role=='three_proxy'){
        this.menuList.splice(1, 1)
      }
    },
    //控制背景颜色
    is_Statistics(){
      if(!this.$global.getCookie('activePath')){
        this.activePath='/Statistics'
        this.StatisticsBk()
      }else{
        this.activePath=this.$global.getCookie('activePath')
      }
    },
    // 导航栏切换事件
    menuItem(index){
      this.activePath=index
      this.$global.setCookie('activePath',this.activePath)
    },
    // 退出
    logout(cmditem){
      //清空token
      this.$global.delCookie('ctoken')
      this.$global.delCookie('loginData')
      this.$global.delCookie('activePath')
      //跳转到登录页
      window.location.href = global.loginURL;
    },
    // 总览Statistics背景
    StatisticsBk(){
      this.$parent.statistics();
    }
  }
}
</script>
<style lang="less" scoped>
  .left-cont {
    width: 215px;
  }
  .home /deep/ .el-main {
    width: 100%;
  }
  .home /deep/ .el-scrollbar__wrap {
    overflow: inherit;
  }
  .home /deep/ .el-scrollbar__wrap {
    overflow-y: scroll !important;
  }
  .el-container,.home{
    height: 100%;
    overflow: hidden;
  }
  .el-header{
    height: 54px!important;
    background-color: #ffffff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-right{
      display: flex;
      align-items: center;
      span{
        font-size: 24px;
        font-style: normal;
        color: #FF6056;
        padding: 0 20px;
      }
    }
  }
  .el-aside{
    width: 200px!important;
    // max-width: 200px!important;
    background-color: #ffffff;
    margin-top: 18px;
    box-shadow: 0 0 9px #e7e7e7;
    border-radius: 0px 6px 0px 0px;
  }
  .el-aside::-webkit-scrollbar-thumb {
    background-color: rgb(209, 209, 209);
    border-radius: 8px;
  }
  .el-aside::-webkit-scrollbar {
      width: 5px;
      height: 1px;
  }
  .el-menu{
    border-right: none;
  }
  .el-menu-item{
    text-align: center;
    font-size: 14px;
    color: #252530;
    font-weight: 400;
  }
  /deep/.el-submenu__title{
    text-align: center;
    font-size: 14px;
    font-weight: 400;
  }
  .active::before{
    content:'';
    background-color: #FF6056;
    position: absolute;
    right: 0;
    top: 50%;
    transform:translate(0,-50%);
    width: 6px;
    height: 26px;
  }
  .el-submenu .el-menu-item{
    min-width: 150px;
    font-size: 12px;
    display: flex;
    margin-left: 30px;
  }
  /deep/.el-main{
    margin-top: 20px!important;
    // padding: 20px 0;
    padding: 0!important;
  }
  /deep/.el-menu-item:hover{
    background: rgba(255, 96, 86, 0.05);
  }
  /deep/.el-submenu__title:hover{
    background: rgba(255, 96, 86, 0.05);
  }
  /deep/.el-menu-item.is-active{
    background: rgba(255, 96, 86, 0.05);
  }
  .el-aside{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .version{
      text-align: center;
      margin-bottom: 20px;
      .version-item{
        display: flex;
        justify-content: center;
        font-size: 12px;
        color: #BABEC7;
        .version-left{
          width: 50px;
        }
      }
    }
  }
  /deep/.el-scrollbar__view,/deep/.el-aside{
    height: calc(100vh - 80px);
  }
</style>
